import {
    DollarSign,
    Tag,
    Users,
    Chrome,
    BarChart,
    Settings,
    LogIn,
    Gift,
    BarChart2
} from 'react-feather';
import apiService from '../components/services/api.service';
import authService from '../components/services/auth.service';

export const MENUITEMS = [
    {
        title: 'Alertas', 
        icon: Tag, 
        type: 'sub-count', 
        active: false, 
        count: 0,
        children: [
            { 
                path: '/charges', 
                title: 'Pagos Clientes', 
                type: 'link-count',
                count: 0
            },
            { 
                path: '/change-address-request', 
                title: 'Direcciones de usuarios', 
                type: 'link-count',
                count: 0
            },
            {   
                path: '/change-user-request', 
                title: 'Datos de usuarios', 
                type: 'link-count',
                count: 0
            },
        ]
    },
    {
        title: 'Órdenes', icon: Chrome, type: 'sub', active: false, children: [
            { path: '/orders', title: 'Listado de órdenes', type: 'link' },
            { path: '/orders-delivery', title: 'Ordenes del dia', type: 'link' },
            { path: '/collections', title: 'Cobranzas', type: 'link' },
            { path: '/all-cash-user', title: 'Créditos', type: 'link' },
        ]
    },
    {
        title: 'Gestión Comercio', icon: BarChart2, type: 'sub', active: false, children: [
            { path: '/product-stock', title: 'Gestión de Stocks', type: 'link' },
            { path: '/product-list', title: 'Listado de productos', type: 'link' },
            { path: '/add-product', title: 'Añadir Producto', type: 'link' },
            { path: '/category', title: 'Categorías', type: 'link' },
            { path: '/sub-category', title: 'Subcategorías', type: 'link' },
            { path: '/promotions', title: 'Promociones', type: 'link' },
            { path: '/banners', title: 'Banners', type: 'link' },
            { path: '/schedules', title: 'Horarios de atención', type: 'link' },
            { path: '/pickup-windows', title: 'Ventanas de entregas', type: 'link' },
            { path: '/grammage', title: 'Etiquetas', type: 'link' },
            
        ]
    },
   /* {
        title: 'Up selling', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/list-selling', title: 'Venta sugestiva', type: 'link' },
            { path: '/create-selling', title: 'Crear selling', type: 'link' },
        ]
    },*/
    {
        title: 'Analítica', icon: BarChart, type: 'sub', active: false, children: [
            { path: '/', title: 'Variables Generales', type: 'link' },
            { path: '/sku-monthly-sale', title: 'Desempeño SKUs', type: 'link' },
            { path: '/sku-monthly-sale-customer', title: 'Desempeño Clientes', type: 'link' },
            { path: '/monthly-sale', title: 'Ventas mensuales', type: 'link' },
            { path: '/log-user', title: 'Logs', type: 'link' },
            { path: '/abandoned-cart-logs', title: 'Carritos Abandonados', type: 'link' },
            { path: '/non-buyer-users', title: 'Usuarios que no han comprado nunca', type: 'link' },
            { path: '/low-purchase-history-customers', title: 'Clientes con menos de 5 compras', type: 'link' },
            { path: '/inactive-customers', title: 'Clientes inactivos (30 días)', type: 'link' },
        ]
    },
    {
        title: 'Usuarios', icon: Users, type: 'sub', active: false, children: [
            { path: '/list-user', title: 'Lista de Usuarios', type: 'link' },
            { path: '/create-user', title: 'Añadir Usuario', type: 'link' },
            { path: '/list-pending-user', title: 'Aprobar referidos', type: 'link' },
            { path: '/import-user', title: 'Importar Usuarios', type: 'link' },
        ]
    },
    {
        title: 'Promociones', icon:Gift, type: 'sub', active: false, children: [
            { path: '/list-codes', title: 'Lista de códigos', type: 'link' },
            { path: '/create-code', title: 'Crear código', type: 'link' },
            { path: '/list-selling', title: 'Lista Up selling', type: 'link' },
        ]
    },
    {
        title: 'Seguridad', icon:Gift, type: 'sub', active: false, children: [
            { path: '/block-users', title: 'Multingreso', type: 'link' },
            { path: '/block-users-simultaneous-ips', title: 'Simultáneo', type: 'link' },
            { path: '/online-users', title: 'Usuarios en línea', type: 'link' },
        ]
    },
    {
        title: 'Configuración', path: '/settings', icon: Settings, type: 'link', active: false
    },
    {
        title: 'Cerrar Sesión', path: '/auth/login', icon: LogIn, type: 'logout', active: false
    }
];

export const updateMenuItemsCount = async () => {
    try {
        const user = authService.getCurrentUser();
        
        const response = await apiService.alertsCount(user.token); 
        
        //const alertCount = response.data;
        const { data, totalCharges, totalAddress, totalUsersTemporal } = response;


        const alertItem = MENUITEMS.find(item => item.title === "Alertas");
        if (alertItem) {
            alertItem.count = data;

            alertItem.children.forEach(child => {
                switch (child.path) {
                    case '/charges':
                        child.count = totalCharges;
                        break;
                    case '/change-address-request':
                        child.count = totalAddress;
                        break;
                    case '/change-user-request':
                        child.count = totalUsersTemporal;
                        break;
                    default:
                        child.count = 0;
                }
            });
            
        }

        return MENUITEMS;
    } catch (error) {
        console.error("Error al obtener el count:", error);
        return MENUITEMS;
    }
};