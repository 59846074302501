import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

let ambassador_users = [];
let itemsAllUsers = [];

class Tabcreateselling extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeOldPrice = this.onChangeOldPrice.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
   

    this.state = {
      name: "",
      price: "",
      status: "ACTIVATED",
      errors: {},
    };
  }

  componentWillMount() {
    this.getDataUsers();
    this.getDataUpselling();
  }

  onChangeStatus(e) {
    this.setState({
      status: e.target.value,
    });
  }

  

  onChangeName(e) {
    this.setState({
    name: e.target.value,
    });
  }

  onChangeOldPrice(e) {
    this.setState({
      old_price: e.target.value,
    });
  }

  onChangePrice(e) {
    this.setState({
      price: e.target.value,
    });
  }


//obtener datos de products
  getDataUsers() {
    var user = AuthService.getCurrentUser();
    ApiService.getCategoriesList(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          this.items = res.data.map(function (row) {
            return {
              Id: row.id,
              name: row.name,
              old_price: row.old_price,
              price: row.price,
              status: row.status
            };
          });
        this.setState({ results: this.items }); 
        
        this.setState({ dataUsersAll: itemsAllUsers });
          if (this.props.action == 'update') {
            let id_user = this.props.id_user;
            this.getDataSelling(id_user);
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getDataSelling(id_user){
    var user = AuthService.getCurrentUser();
    let id_product = this.props.match.params.id;
    ApiService.getProductDetails(user.token,id_product).then(
        (res) => {
            //console.log(res)
            if(res && res.status === 'success')
            {
                this.setState({ name: res.data.name });
                this.setState({ old_price: res.data.price });
                this.setState({ status: res.data.status });
         } },
    
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
}

  getDataUpselling() {
    var user = AuthService.getCurrentUser();
    ApiService.getSelling(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          this.items = res.data.map(function (row) {
            return {
              name: row.name,
              old_price: row.old_price,
              price: row.price,
              status: row.status
            };
          });
        this.setState({ results: this.items }); 
        
        this.setState({ dataUsersAll: itemsAllUsers });
          if (this.props.action == 'update') {
            let id_user = this.props.id_user;
            this.getSelling(id_user);
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getSelling(id_user){
    var user = AuthService.getCurrentUser();
    let id_product = this.props.match.params.id;
    ApiService.getSellingDetails(user.token,id_product).then(
        (res) => {
            //console.log(res)
            if(res && res.status === 'success')
            {
                this.setState({ name: res.data.name });
                this.setState({ old_price: res.data.old_price });
                this.setState({ price: res.data.price });
                this.setState({ status: res.data.status });
        } },
    
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  }

//fin

  handleRegister(e) {
    //e.preventDefault();
    
    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

      
      //console.log('this.checkBtn.context._errors.length', this.checkBtn.context._errors.length);
      if (
        this.checkBtn.context._errors.length === 0
      ) {

      var user = AuthService.getCurrentUser();
      //console.log(user.token);
      let data_selling = {};
      
      data_selling['name'] = this.state.name;
      data_selling['price'] = this.state.price;
      data_selling['old_price'] = this.state.old_price;
      data_selling['status'] = this.state.status;

      ApiService.putSelling(user.token, data_selling).then(
        (res) => {
          if (res) {
            if (res && res.status === 'success') {
              this.props.history.push('/list-selling');
              toast.success('Up selling creado!');
            } else {
              toast.error(res.message);
            }
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
   } else {
      this.setState({
        loading: false,
      });
    }
  }

  handleUpdate(e) {

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    
    if (
      this.checkBtn.context._errors.length === 0
    ) {
      var user = AuthService.getCurrentUser();
      let data_selling = {};
      data_selling['id_product'] = this.props.match.params.id;
      data_selling['name'] = this.state.name;
      data_selling['price'] = this.state.price;
      data_selling['old_price'] = this.state.old_price;
      data_selling['status'] = this.state.status;
      

      ApiService.postSelling(user.token, data_selling).then(
        (res) => {
          if (res) {
            if (res && res.status == 'success') {
              toast.success('up selling actualizado!');
              this.props.history.push('/list-selling');
            } else {
              toast.error(res.message);
            }
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      const errors = {};

      // Actualiza el estado con los errores encontrados
      this.setState({ errors, loading: false }, () => {
        console.log('Errores de validación:', this.state.errors);
        });
      }

  }


  formPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    return (
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link">Up selling</Tab>
            
          </TabList>
          <TabPanel>
            <Form
              
              autoComplete="off"
              ref={(c) => {
                this.form = c;
              }}
              onSubmit={this.formPreventDefault}
            >
              <h4>Datos </h4>
              <div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Nombre 
                  </label>
                  <Input
                    name="name"
                    value={this.state.name}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeName}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Precio
                  </label>
                  <Input
                    name="old_price"
                    value={this.state.old_price}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeOldPrice}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Precio nuevo
                  </label>
                  <Input
                    name="price"
                    value={this.state.price}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangePrice}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Estado 
              </label>
              <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label className="d-block" style={{ marginRight: '1rem', marginLeft: '2rem' }}>
                  <Input
                    id="edo-ani"
                    name="status"
                    value="ACTIVATED"
                    type="radio"
                    className="radio_animated"
                    checked={this.state.status === 'ACTIVATED'}
                    onChange={this.onChangeStatus}
                    validations={[required]}
                  />
                  ACTIVADO
                </label>
                <label className="d-block">
                  <Input
                    id="edo-ani"
                    name="status"
                    value="DESACTIVATED"
                    type="radio"
                    className="radio_animated"
                    checked={this.state.status === 'DESACTIVATED'}
                    onChange={this.onChangeStatus}
                    validations={[required]}
                  />
                DESACTIVADO
                </label>
              </div>
            </div>
            </div>
              <button
                onClick={() =>
                  this.props.action == 'create'
                    ? this.handleRegister()
                    : this.handleUpdate()
                }
                className="btn btn-primary"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Guardar</span>
              </button>
              

              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: 'none' }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </TabPanel>
          
        </Tabs>
        
      </Fragment>
    );
  }
}

//export default Tabset_user;
export default withRouter (Tabcreateselling);