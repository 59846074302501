import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import DatatableGroup from '../common/datatable-group';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";

export class Online_Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCateg: [], // Valores únicos para el select
      results: [], // Datos originales
      filteredResults: [], // Datos filtrados
      selectedGroup: '', // Grupo seleccionado para filtrar
    };
  }

  componentWillMount() {
    this.getDataUser();
  }

  getDataUser() {
    var user = AuthService.getCurrentUser();
    ApiService.getOnlineUsers(user.token).then(
      (res) => {
        if (res && res.status === 'success') {
          const items = res.data.map((row) => ({
            Grupo: row.hourly_interval,  // Agrupar por 'hourly_interval'
            ID: row.id_user,
            'Nombres': row.fullname,
            Navegador: row.browser,
            Celular: row.phonenumber,
            'Último registro': row.last_seen,
          }));

          // Extraemos los valores únicos de 'Grupo' (hourly_interval)
          const dataCateg = [...new Set(items.map(item => item.Grupo))];

          console.log("Datos obtenidos:", items);  // Imprimir los datos obtenidos
          this.setState({ results: items, filteredResults: items, dataCateg });
        }
      },
      error => {
        const resMessage = (error.response && error.response.data && error.response.data.message) ||
          error.message || error.toString();
      }
    );
  }

  // Maneja el cambio en el select para filtrar por el grupo
  handleGroupChange = (event) => {
    const selectedGroup = event.target.value;
    this.setState({ selectedGroup }, () => {
      this.filterDataByGroup(selectedGroup);
    });
  };

  filterDataByGroup(group) {
    // Filtramos los resultados según el grupo seleccionado
    const filteredResults = group
        ? this.state.results.filter(item => item.Grupo === group)
        : this.state.results; // Si no hay grupo seleccionado, mostramos todos los resultados

    // Actualizamos el estado
    this.setState({ filteredResults });
}

renderResults(length) {
    // Aseguramos que se muestre un mensaje cuando no haya datos
    if (this.state.filteredResults.length === 0) {
        return 'No hay datos.';
    }

    // Pasamos los datos filtrados al componente DatatableGroup
    return (
        <DatatableGroup
            myData={this.state.filteredResults} // Usamos los datos filtrados
            pageSize={length <= 50 ? length : 50}
            pagination={true}
            class="-striped -highlight"
        />
    );
}

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Seguridad" parent="Usuarios" />
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h5>Usuarios en línea</h5>
            </div>
            <div className="card-body">
              {/* Select para elegir el grupo (valores de hourly_interval) */}
              <div className="form-group">
                <label>Seleccione el grupo:</label>
                <select 
                  className="form-control"
                  onChange={this.handleGroupChange}
                  value={this.state.selectedGroup}
                >
                  <option value="">Todos</option>
                  {this.state.dataCateg.map((group, index) => (
                    <option key={index} value={group}>
                      {group}
                    </option>
                  ))}
                </select>
              </div>

              <div className="clearfix"></div>
              <div 
                id="batchDelete" 
                className="category-table user-list order-table coupon-list-delete text-left">
                {this.renderResults(this.state.filteredResults.length)}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Online_Users;
