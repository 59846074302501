import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { Link } from 'react-router-dom';
// import Swal from 'sweetalert2'

export class VerPickupWindowsDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.myData != prevState.myData){
            this.setState({
                myData: prevProps.myData
            })
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    deleteUser(row){
        var user = AuthService.getCurrentUser();
        ApiService.deleteUser(user.token,row.original.id).then(
        (res) => {
            toast.success("Usuario eliminado!");
            //this.props.history.push("/category");
            //window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
     )
    }

    editProduct(row){
        console.log(row);
        //window.location.reload();
    }

    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state
        const columns = [];
        var handlerEditPickup  =   this.props.handlerEditPickup;
        var handlerDeletePickup  =   this.props.handlerDeletePickup;
        var handlerStatusPickup  =   this.props.handlerStatusPickup;
        for (var key in myData[0]) {

            let editable = this.renderEditable
            editable = null

            var c_name = '';

            switch(this.Capitalize(key.toString())){
                case 'N': c_name = 'N°';break;
                case 'Hora_Inicio': c_name = 'Hora Inicio';break;
                case 'Hora_Fin': c_name = 'Hora Fin';break;
                case 'Estado': c_name = 'Estado';break;
                default: c_name = '';break;
            }
            //if(this.Capitalize(key.toString()))

            if(key != "id"  && key != "id_schedule")
            {
                columns.push(
                    {
                        Header: <b>{c_name}</b>,
                        accessor: key,
                        width: 150,
                        Cell: editable,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
        }

        if (multiSelectOption == true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } else {
            columns.push(
                {
                    Header: <b>Acción</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div>
                        <Link to={`#`} title="Activar/Desactivar" onClick={() => handlerStatusPickup(row)}
                        ><i className="fa fa-eye" style={{ width: 25, fontSize: 20, padding: 11,color:'#blue' }}></i></Link>   
                        <Link to={`#`} title="Editar" onClick={() => handlerEditPickup(row)}
                        ><i className="fa fa-pencil" style={{ width: 25, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></Link> 
                        <Link to={`#`} title="Eliminar" onClick={() => handlerDeletePickup(row)}
                        ><i className="fa fa-trash" style={{ width: 25, fontSize: 20, padding: 11,color:'rgb(251 15 15)' }}></i></Link> 
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )
        }

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={`custom-table ${myClass}`}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        )
    }
}

export default VerPickupWindowsDatatable
