import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class DatatableGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedValues: [],
        };
    }

    selectRow = (e, id) => {
        this.setState(prevState => {
            const checkedValues = e.target.checked
                ? [...prevState.checkedValues, id]
                : prevState.checkedValues.filter(item => item !== id);
            return { checkedValues };
        });
    }

    handleRemoveRow = () => {
        const { checkedValues } = this.state;
        if (checkedValues.length === 0) {
            toast.warning("No rows selected!");
            return;
        }

        const updatedData = this.props.myData.filter(item => !checkedValues.includes(item.id));
        this.setState({
            checkedValues: [] // Limpiar las filas seleccionadas
        });
        toast.success("Successfully Deleted!");
        // Puedes pasar los datos actualizados al componente padre si es necesario
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.props.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    // Aquí deberías enviar la data modificada al componente padre
                }}
                dangerouslySetInnerHTML={{
                    __html: this.props.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    };

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { checkedValues } = this.state;
        const columns = [];

        for (const key in this.props.myData[0]) {
            let editable = this.renderEditable;
            if (["image", "status", "avtar", "vendor", "order_status"].includes(key)) {
                editable = null;
            }

            columns.push({
                Header: <b>{this.Capitalize(key)}</b>,
                accessor: key,
                Cell: editable,
                style: { textAlign: 'center' }
            });
        }

        if (multiSelectOption) {
            columns.push({
                Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                    onClick={this.handleRemoveRow}>Delete</button>,
                id: 'delete',
                sortable: false,
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <div>
                        <input
                            type="checkbox"
                            checked={checkedValues.includes(row.original.id)}
                            onChange={e => this.selectRow(e, row.original.id)}
                        />
                    </div>
                ),
            });
        }

        return (
            <Fragment>
                <ReactTable
                    data={this.props.myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        );
    }
}

export default DatatableGroup;
