import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listUser';
import Datatable from '../common/datatable';
import VerCodeDatatable from '../common/ver-code-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';



export class List_codes extends Component {

    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.deleteCode = this.deleteCode.bind(this);
        this.state = {
            dataCateg: [],
            results: [],
            filter: '',
            temp: [],
        };
    }

    componentDidMount() {
        this.getDataUser();
    }

    handler(data_row) {
        this.props.history.push("/edit-codes/" + data_row.original.id);
    }

    deleteCode(data_row) {
        Swal.fire({
            title: 'Confirmar',
            text: '¿Desea eliminar '+ data_row.original.Codigo + '?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                const user = AuthService.getCurrentUser();
                ApiService.deleteCode(user.token, data_row.original.id).then(
                    (res) => {
                        toast.success("Código eliminado!");
                        this.getDataUser();
                    },
                    error => {
                        const resMessage =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }
        });
    }

    onChangeFilter(e) {

        this.setState({
            filter: e.target.value
        }, function () {
            console.log('');
        });

        const val = e.target.value.toLowerCase();
       
            if (this.state.temp.length !== 0) {
                var columns = Object.keys(this.state.temp[0]);
                //Los botones de acciones se pintan despues
                columns.splice(columns.length);
                //columns.splice(columns.length - 1);

                if (!columns.length)
                    return;
                  const rows = this.state.temp.filter(function (d) {
                    for (let i = 0; i <= columns.length; i++) {
                      let column = columns[i];
                      if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                        return true;
                      }
                    }
                  });

                  this.setState({
                    results: rows
                }, function () {
                    //console.log('results',this.state.results);
                    //console.log('lenght',this.state.results.length)
                    this.renderResults(this.state.results.length);
                });
                  /*this.Inventarios = rows;
                  let articulosAlmacenes;
                  articulosAlmacenes = { 'Articulos': this.Inventarios }
                  this.buildpurchaseForm(articulosAlmacenes);*/
                }

                e.preventDefault();
            }
            
          componentWillMount() {
              this.getDataUser();
          }
        
    getDataUser() {
        const user = AuthService.getCurrentUser();
        ApiService.getCodes(user.token).then(
            (res) => {
                if (res && res.status === 'success') {
                     const items = res.data.map(function(row) {
                        return {
                        id: row.id, 
                        Promoción: row.code_name, 
                        "Código": row.code,
                        Descuento: row.discount_value,
                        Estado: row.code_status,
                        }
                    })
                      this.setState({ results:items });
                                 this.setState({ temp:items });
                                 this.renderResults(this.state.results.length);
                                 }
                             },
                             error => {
                               const resMessage =
                                 (error.response &&
                                   error.response.data &&
                                   error.response.data.message) ||
                                 error.message ||
                                 error.toString();
                             }
                           );
                     }
                 

    renderResults(length) {
        return this.state.results.length === 0
            ? 'No hay datos.'
            : <VerCodeDatatable
                multiSelectOption={false}
                myData={this.state.results}
                pageSize={this.state.results.length <= 10 ? this.state.results.length : 10}
                pagination={true}
                class="-striped -highlight"
                handler={this.handler}
                deleteCode={this.deleteCode}
            />;
    }

    render() {
        return (
            <>
                <Breadcrumb title="Códigos" parent="Codes" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header"></div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <Link to="/create-code" className="btn btn-secondary">Crear Codigo</Link>
                            </div>
                            <div className="clearfix"></div>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                <div className="col-md-6 table-filter">
                                    <input 
                                        placeholder="Escriba para filtrar todas las columnas"
                                        name="filter" 
                                        type="text" 
                                        className="form-control" 
                                        autoComplete="off"
                                        onInput={this.onChangeFilter}
                                    />
                                </div>
                                {this.renderResults()}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default List_codes;
