import axios from 'axios';

const API_URL = process.env.REACT_APP_URL + '897623u9jkjdsjkdjsk/';

class ApiService {
  getCategories(access_token) {
    return axios
      .get(API_URL + 'categories', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postCategory(access_token, formData) {
    return axios
      .post(API_URL + 'categories', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateCategory(access_token, formData) {
    return axios
      .put(API_URL + 'categories', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteCategory(access_token, id) {
    return axios
      .delete(API_URL + 'categories/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getProducts(access_token) {
    return axios
      .get(API_URL + 'products', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getProductDetails(access_token, id) {
    return axios
      .get(API_URL + 'products/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteProduct(access_token, id) {
    return axios
      .delete(API_URL + 'products/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postProduct(access_token, formData) {
    return axios
      .post(API_URL + 'products', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateProduct(access_token, formData) {
    return axios
      .put(API_URL + 'products', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postUser(access_token, formData) {
    return axios
      .post(API_URL + 'users', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putUser(access_token, formData) {
    return axios
      .put(API_URL + 'users', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUsers(access_token) {
    return axios
      .get(API_URL + 'users ', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getConfirmUsers(access_token) {
    return axios
      .get(API_URL + 'users/state/1', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBloquedUsers(access_token) {
    return axios
      .get(API_URL + 'users-blocked', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPendingUsers(access_token) {
    return axios
      .get(API_URL + 'users/state/2', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserDetails(access_token, id) {
    return axios
      .get(API_URL + 'users/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteUser(access_token, id) {
    return axios
      .delete(API_URL + 'users/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getDisttricts(access_token) {
    return axios
      .get(API_URL + 'districts', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserAddress(addressId, access_token) {
    return axios
      .get(API_URL + `user_address/${addressId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }



  async addAddress(access_token, data) {
    return axios.post(API_URL + 'user_address', data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 422,
    });
  }

  async updateAddress(access_token, addressId, data) {
    return axios.put(API_URL + `user_address/${addressId}`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 422,
    });
  }

  getOrders(access_token) {
    return axios
      .get(API_URL + 'purchase', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getOrdersToday(access_token) {
    return axios
      .get(API_URL + 'purchase_today', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postAceptPurchase(access_token, data) {
    return axios
      .post(API_URL + 'purchase/approve ', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postCancelPurchase(access_token, data) {
    return axios
      .post(API_URL + 'purchase/cancel', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postRemovePurchase(access_token, data) {
    return axios
      .post(API_URL + 'purchase/remove', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getOrderDetails(access_token, id) {
    return axios
      .get(API_URL + 'purchase/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postStateUser(access_token, data) {
    return axios
      .post(API_URL + 'users/state', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSchedules(access_token) {
    return axios
      .get(API_URL + 'schedules', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPickupWindow(access_token, id) {
    return axios
      .get(API_URL + 'pickup-windows/' + id + '/0', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postPickupWindow(access_token, data) {
    return axios
      .post(API_URL + 'pickup-windows', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putPickupWindow(access_token, data) {
    return axios
      .put(API_URL + 'pickup-windows', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deletePickupWindow(access_token, id) {
    return axios
      .delete(API_URL + 'pickup-windows/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateStatusPickupWindow(access_token, id) {
    return axios
      .get(API_URL + 'pickup-windows-status/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSchedulesDetails(access_token, id) {
    return axios
      .get(API_URL + 'site_hours/' + id + '/0', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postSchedulesDetails(access_token, data) {
    return axios
      .post(API_URL + 'site_hours', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putSchedulesDetails(access_token, data) {
    return axios
      .put(API_URL + 'site_hours', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteSchedulesDetails(access_token, id) {
    return axios
      .delete(API_URL + 'site_hours/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCashUser(access_token, id) {
    return axios
      .get(API_URL + 'internal_accounts/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getAddressUser(access_token, id) {
    return axios
      .get(API_URL + 'addresses/ambassador/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putAddresssDeliveryUser(access_token, data) {
    return axios
      .put(API_URL + 'addresses/delivery', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postCashUser(access_token, data) {
    return axios
      .post(API_URL + 'internal_accounts', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getUserChangeRequest(access_token) {
    return axios
      .get(API_URL + 'users/refered-temporal', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putChangeUserRequest(access_token, data) {
    return axios
      .put(API_URL + 'users/refered-temporal', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getAddressChangeRequest(access_token) {
    return axios
      .get(API_URL + 'addresses/temporal', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putChangeAddressRequest(access_token, data) {
    return axios
      .put(API_URL + 'addresses/temporal/admin', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getChildProd(access_token, id) {
    return axios
      .get(API_URL + 'products/child/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putChildProd(access_token, data) {
    return axios
      .put(API_URL + 'products/child', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteChildProd(access_token, id) {
    return axios
      .delete(API_URL + 'products/child/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postChildProd(access_token, data) {
    return axios
      .post(API_URL + 'products/child', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getProductStock(access_token) {
    return axios
      .get(API_URL + 'products_stock', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postProductStock(access_token, data) {
    return axios
      .post(API_URL + 'products_stock', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postUserImport(access_token, formData) {
    return axios
      .post(API_URL + 'users/import', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPurchaseCharge(access_token) {
    return axios
      .get(API_URL + 'purchase_charge', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postStatusDelivery(access_token, data) {
    return axios
      .post(API_URL + 'purchase/status_delivery', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getDataPurchaseCharge(access_token, id) {
    return axios
      .get(API_URL + 'purchase/charges/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updatePurchaseStatusCharge(access_token, formData) {
    return axios
      .post(API_URL + '_purchase/update/status_charge', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        //console.log('postPurchaseCharge--- ',response);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postPurchaseCharge(access_token, formData) {
    return axios
      .post(API_URL + "purchase/charges", formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        //console.log('postPurchaseCharge--- ',response);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async updatePurchaseCharge(access_token, id, data) {
    const response = await axios.put(
      `${API_URL}purchase/charges/update/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      }
    );

    //console.log(response);

    return response;
    /*
      .then((response) => {
        //console.log('postPurchaseCharge--- ',response);
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });*/
  }

  deletePurchaseCharge(access_token, id) {
    return axios
      .delete(API_URL + 'purchase/charges/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getDataGrammage(access_token, id) {
    return axios
      .get(API_URL + 'grammage/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postGrammge(access_token, formData) {
    return axios
      .post(API_URL + 'grammage', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateGrammage(access_token, formData) {
    return axios
      .put(API_URL + 'grammage', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteGrammage(access_token, id) {
    return axios
      .delete(API_URL + 'grammage/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getDataBanners(access_token, id) {
    return axios
      .get(API_URL + 'banners', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postBanner(access_token, formData) {
    return axios
      .post(API_URL + 'banner', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateBanner(access_token, formData) {
    return axios
      .put(API_URL + 'banner', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteBanner(access_token, id) {
    return axios
      .delete(API_URL + 'banner/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCategoriesList(access_token) {
    return axios
      .get(API_URL + 'categories-list', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSettings(access_token) {
    return axios
      .get(API_URL + 'settings', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updateSettings(access_token, formData) {
    return axios
      .put(API_URL + 'settings', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPromotions(access_token) {
    return axios
      .get(API_URL + 'promotions', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getCharges(access_token) {
    return await axios
      .get(API_URL + 'purchase/get/charges', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
  }

  getPresentaciones(access_token) {
    return axios
      .get(API_URL + 'products_child', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postPromocion(access_token, data) {
    return axios
      .post(API_URL + 'promotions', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPromotionDetails(access_token, id) {
    return axios
      .get(API_URL + 'promotions/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  updatePromotion(access_token, data) {
    return axios
      .put(API_URL + 'promotions', data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deletePromotion(access_token, id) {
    return axios
      .delete(API_URL + 'promotions/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteAddress(access_token, id) {
    return axios
      .delete(API_URL + 'address/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Trae los meses y años de ventas
  getDataReportsMonthsAndYears(access_token) {
    return axios
      .get(API_URL + 'DataReportsMonthsAndYears', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Reporte de Cash de todos los usuarios por día

  getReportCashUser(access_token, formData) {
    return axios
      .post(API_URL + 'allinternalaccounts', formData,  {
        headers: {
          Authorization: `Bearer ${access_token}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Trae los meses y años de ventas
  getDataReportsMonthsAndYearsPending(access_token) {
    return axios
      .get(API_URL + 'DataReportsMonthsAndYearsPending', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getOrdersReportFilter(access_token, month, year) {
    return axios
      .get(API_URL + `purchaseReportPending/${month}/${year}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getOrdersFilter(access_token, month, year) {
    return axios
      .get(API_URL + `purchasePending/${month}/${year}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Trae los meses y años de ventas - Sku
  getDataReportsMonthsAndYearsSku(access_token) {
    return axios
      .get(API_URL + 'DataReportsMonthsAndYearsSku', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Traer los productos por sku por meses
  getProductsForMonthFilter(access_token, month, year) {
    return axios
      .get(API_URL + `productsForMonth/${month}/${year}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Trae los Usuarios
  getDataCustomers(access_token) {
    return axios
      .get(API_URL + `DataCustomers`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

   // Traer los productos por sku por meses y cliente
   getProductsForMonthCustomerFilter(access_token, month, year, customer) {
    return axios
      .get(API_URL + `productsForMonthCustomer/${month}/${year}/${customer}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Trae los Productos
  getDataProducts(access_token) {
    return axios
      .get(API_URL + `DataProducts`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Traer los productos por sku por meses y cliente
  getProductsForMonthCustomer(access_token, month, year, customer) {
    return axios
      .get(API_URL + `getProductsForMonthCustomer/${month}/${year}/${customer}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

   // Traer los reportes de productos vendidos por sku
   getProductsReport(access_token, month, year) {
    return axios
      .get(API_URL + `listReportProductsForMonth/${month}/${year}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Trae los clientes por meses y años - Sku
  getDataReportsCustomersMonthsAndYears(access_token) {
    return axios
      .get(API_URL + 'DataReportsCustomersMonthsAndYears', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Traer los reportes de productos vendidos por sku
  getCustomersReport(access_token, month, year) {
    return axios
      .get(API_URL + `listReportCustomersForMonth/${month}/${year}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Traer los reportes de productos vendidos por sku
  alertsCount(access_token) {
    return axios
      .get(API_URL + `alerts`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getChangeLog(access_token) {
    return axios
      .get(API_URL + 'changelog', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCodes(access_token) {
    return axios
      .get(API_URL + 'codes ', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putCode(access_token, formData) {
    return axios
      .put(API_URL + 'codes', formData,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postCodes(access_token, formData) {
    return axios
      .post(API_URL + 'codes', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCodeDetails(access_token, id) {
    return axios
      .get(API_URL + 'codes/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteCode(access_token, id) {
    return axios
      .delete(API_URL + 'codes/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  getSelling(access_token) {
    return axios
      .get(API_URL + 'selling ', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  putSelling(access_token, formData) {
    return axios
      .put(API_URL + 'selling', formData,{
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': `application/json`,
          cache: false,
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  postSelling(access_token, formData) {
    return axios
      .post(API_URL + 'selling', formData, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          cache: false, 
          contentType: false,
          processData: false,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSellingDetails(access_token, id) {
    return axios
      .get(API_URL + 'selling/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  deleteSelling(access_token, id) {
    return axios
      .delete(API_URL + 'selling/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  } 

  getAudit(access_token, id) {
    return axios
    .get(API_URL + 'codes-audit/' + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //abandoned-cart
  getAbandonedCart(access_token) {
    return axios
      .get(API_URL + 'abandoned-cart', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getNonBuyerUsers(access_token) {
    return axios
      .get(API_URL + 'non-buyer-users', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getLowPurchaseHistoryCustomers(access_token) {
    return axios
      .get(API_URL + 'low-purchase-history-customers', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }


  getInactiveCustomers(access_token) {
    return axios
      .get(API_URL + 'inactive-customers', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBlockUserForMultipleIPs(access_token) {
    return axios
      .get(API_URL + 'block-users', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getBlockUserForSimultaneousIPs(access_token) {
    return axios
      .get(API_URL + 'block-users-simultaneous-ips', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getOnlineUsers(access_token) {
    return axios
      .get(API_URL + 'online-users', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }
   
}

export default new ApiService();
