import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabselling';

export class EditSelling extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Fragment>
        <Breadcrumb title="Editar Up Selling" parent="Selling" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                </div>
                <div className="card-body">
                  <Tabset
                    action={'update'}
                    id={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default EditSelling;