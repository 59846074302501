import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Tabset from './tabset'; 

export class Create_code extends Component {
     render() {
       return (
         <Fragment>
           <Breadcrumb title="Crear Código" parent="Codes" />
           <div className="container-fluid">
             <div className="row">
               <div className="col-sm-12">
                 <div className="card">
                   <div className="card-body">
                     <Tabset action={'create'}/>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </Fragment>
       );
     }
   }

export default Create_code;

