import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import AuthService from '../services/auth.service';
import ApiService from '../services/api.service';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
// import TextArea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio!
      </div>
    );
  }
};

let ambassador_users = [];
let itemsAllUsers = [];

class Tabset extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeCodeName = this.onChangeCodeName.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onChangeDiscountValue = this.onChangeDiscountValue.bind(this);
    this.onChangeCodeStatus = this.onChangeCodeStatus.bind(this);
   

    this.state = {
      name: '',
      code_name: '',
      code: '',
      discount_value: '',
      code_status: 'ACTIVADO',
      errors: {},
    };
  }

  componentWillMount() {
    this.getDataUsers();
  }

 onChangeCodeStatus(e) {
    this.setState({
      code_status: e.target.value,
    });
  }

  onChangeCodeName(e) {
    this.setState({
      code_name: e.target.value,
    });
  }

  onChangeCode(e) {
    this.setState({
      code: e.target.value,
    });
  }

  onChangeDiscountValue(e) {
    this.setState({
      discount_value: e.target.value,
    });
  }

  getDataUsers() {
    var user = AuthService.getCurrentUser();
    ApiService.getCodes(user.token).then(
      (res) => {
        if (res && res.status == 'success') {
          this.items = res.data.map(function (row) {
            return {
              Id: row.id,
             "Nombre Código": row.name_code,
              Código: row.code,
              Descuento: row.discount_value,
              Estado: row.code_status,
            };
          });
        this.setState({ results: this.items }); 
        
        this.setState({ dataUsersAll: itemsAllUsers });
          if (this.props.action == 'update') {
            let id_user = this.props.id_user;
            this.getCode(id_user);
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  getCode(id_user) {
    var user = AuthService.getCurrentUser();
    ApiService.getCodeDetails(user.token, id_user).then(
      (res) => {
        //console.log(res)
        if (res && res.status === 'success') {
          this.setState({ code: res.data.code ? res.data.code : '' });
          this.setState({
            code_name: res.data.code_name ? res.data.code_name : '',
          });
          this.setState({ code_status: res.data.code_status ? res.data.code_status: '' });
          this.setState({ discount_value: res.data.discount_value ? res.data.discount_value : '', });
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  }

  handleRegister(e) {
    //e.preventDefault();
    
    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

      
      //console.log('this.checkBtn.context._errors.length', this.checkBtn.context._errors.length);
      if (
        this.checkBtn.context._errors.length === 0
      ) {

      var user = AuthService.getCurrentUser();
      //console.log(user.token);
      let data_codes = {};
      
      data_codes['code'] = this.state.code;
      data_codes['code_name'] = this.state.code_name;
      data_codes['discount_value'] = this.state.discount_value;
      data_codes['code_status'] = this.state.code_status;

      ApiService.postCodes(user.token, data_codes).then(
        (res) => {
          if (res) {
            if (res && res.status === 'success') {
              this.props.history.push('/list-codes');
              toast.success('Código creado!');
            } else {
              toast.error(res.message);
            }
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
   } else {
      this.setState({
        loading: false,
      });
    }
  }

  handleUpdate(e) {

    this.setState({
      message: '',
      loading: true,
    });

    this.form.validateAll();

    
    if (
      this.checkBtn.context._errors.length === 0
    ) {
      var user = AuthService.getCurrentUser();
      let data_codes = {};
      
      data_codes['id'] = this.props.id_user;
      data_codes['code'] = this.state.code;
      data_codes['code_name'] = this.state.code_name;
      data_codes['discount_value'] = this.state.discount_value;
      data_codes['code_status'] = this.state.code_status;
     

      ApiService.putCode(user.token, data_codes).then(
        (res) => {
          if (res) {
            if (res && res.status == 'success') {
              toast.success('Código actualizado!');
              this.props.history.push('/list-codes');
            } else {
              toast.error(res.message);
            }
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      const errors = {};

      // Actualiza el estado con los errores encontrados
      this.setState({ errors, loading: false }, () => {
        console.log('Errores de validación:', this.state.errors);
        });
      }
  
  }


  formPreventDefault(e) {
    e.preventDefault();
  }

  render() {
    return (
      <Fragment>
        <Tabs>
          <TabList className="nav nav-tabs tab-coupon">
            <Tab className="nav-link">Codigo promocional</Tab>
            
          </TabList>
          <TabPanel>
            <Form
              
              autoComplete="off"
              ref={(c) => {
                this.form = c;
              }}
              onSubmit={this.formPreventDefault}
            >
              <h4>Datos del código</h4>
              <div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Nombre del Código
                  </label>
                  <Input
                    name="code_name"
                    value={this.state.code_name}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeCodeName}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label className="col-xl-3 col-md-4">
                    <span>*</span> Código Promocional
                  </label>
                  <Input
                    name="code"
                    value={this.state.code}
                    type="text"
                    className="form-control"
                    style={{ maxWidth: '100%' , margin:'5px' }}
                    onChange={this.onChangeCode}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Estado del código
              </label>
              <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
              <label className="d-block" style={{ marginRight: '1rem', marginLeft: '2rem' }}>
                  <Input
                    id="edo-ani"
                    name="code_status"
                    value="ACTIVADO"
                    type="radio"
                    className="radio_animated"
                    checked={this.state.code_status === 'ACTIVADO'}
                    onChange={this.onChangeCodeStatus}
                    validations={[required]}
                  />
                  ACTIVADO
                </label>
                <label className="d-block">
                  <Input
                    id="edo-ani"
                    name="code_status"
                    value="DESACTIVADO"
                    type="radio"
                    className="radio_animated"
                    checked={this.state.code_status === 'DESACTIVADO'}
                    onChange={this.onChangeCodeStatus}
                    validations={[required]}
                  />
                DESACTIVADO
                </label>
              </div>
            </div>
            </div>
            <div className="form-group">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Valor del Descuento
              </label>
              <Input
                name="discount_value"
                value={this.state.discount_value}
                type="text"
                className="form-control"
                style={{ maxWidth: '100%', margin: '5px' }}
                onChange={this.onChangeDiscountValue}
                validations={this.props.action === 'create' ? [required] : []}
              />
            </div>
              <button
                onClick={() =>
                  this.props.action == 'create'
                    ? this.handleRegister()
                    : this.handleUpdate()
                }
                className="btn btn-primary"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Guardar</span>
              </button>
              

              {this.state.message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: 'none' }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </TabPanel>
          
        </Tabs>
        
      </Fragment>
    );
  }
}

//export default Tabset_user;
export default withRouter (Tabset);