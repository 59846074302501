import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listUser';
import Datatable from '../common/datatable'
import VerProductStockDatatable from '../common/ver-product-stock-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';

export class Ver_list_stock extends Component {

    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.state = {
            dataCateg: [],
            results: [],
            filter: '',
            temp: [],
        };
    }

    handler(data_row) {
        this.props.history.push("/edit-user/"+data_row.original.id);
        window.location.reload();
    }

    deleteUser(data_row) {
       //console.log(data_row)
       Swal.fire({
        title: 'Confirmar',
        text: '¿Desea eliminar '+ data_row.original.Nombre + '?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', 
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
     }).then((result) => {
        if(result.value){
            /*let data = myData;
            data.splice(data_row.index, 1);
            this.setState({ myData: data });
            this.deleteUser(row)*/
            var user = AuthService.getCurrentUser();
            ApiService.deleteUser(user.token,data_row.original.id).then(
                (res) => {
                    toast.success("Usuario eliminado!");
                    this.getDataProductStock();
                    //this.props.history.push("/category");
                    //window.location.reload();
                },
                error => {
                const resMessage =
                    (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                }
            )
       }
     })
    }

    onChangeFilter(e) {

        this.setState({
            filter: e.target.value
        }, function () {
            console.log('');
        });

        const val = e.target.value.toLowerCase();
        //console.log(event);
        if (this.state.temp.length != 0) {
          var columns = Object.keys(this.state.temp[0]);
          //Los botones de acciones se pintan despues
          columns.splice(columns.length);
          //columns.splice(columns.length - 1);
    
          if (!columns.length)
            return;
          const rows = this.state.temp.filter(function (d) {
            for (let i = 0; i <= columns.length; i++) {
              let column = columns[i];
              if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
                return true;
              }
            }
          });
          
          this.setState({
            results: rows
        }, function () {

            this.renderResults(this.state.results.length);
        });

        }



        e.preventDefault();
      }
      
    componentWillMount() {
        this.getDataProductStock();
    }

    getDataProductStock(){
        var user = AuthService.getCurrentUser();
        ApiService.getProductStock(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    const items = res.data.map(function(row) {
                        return { 
                            id: row.id, 
                            Nombre : row.name + (row.size ? " - " + row.size : "") , 
                            Precio : row.price,
                            Costo : row.cost ? row.cost : 0,
                            Stock : row.stock ? Math.trunc(row.stock) : 0,
                            variable_stock: row.stock ? row.stock : 0,
                        }
                     })
                this.setState({ results:items });
                this.setState({ temp:items });
                this.renderResults(this.state.results.length);
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    onSaveStock() {
        console.log('onSaveStock this.state', this.state)

        var user = AuthService.getCurrentUser();
        let data = {
            'products': this.state.results,
        }
        ApiService.postProductStock(user.token,data).then(
            (res) => {
                if(res && res.status == 'success'){
                    Swal.fire("Hecho!", "Stock Actualizado!", "success");

                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );


    }

    renderResults(length){
		return this.state.results.length === 0 
			? 'No hay datos.'
			: <VerProductStockDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={length<=50?length:50}
            pagination={true}
            class="-striped -highlight"
            handler = {this.handler}
            deleteUser = {this.deleteUser}
        />
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Stock de Productos" parent="Users" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                           {/*<h5>User Details</h5>*/}
                        </div>
                        <div className="card-body">
                            <div className="btn-popup pull-right">
                                <a onClick={(e)=>this.onSaveStock()} className="btn btn-secondary">Guardar Stock</a>
                            </div>
                            <div className="clearfix"></div>
                            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                            <div className="col-md-6 table-filter">
                            <input 
                                placeholder="Escriba para filtrar todas las columnas"
                                name="filter" 
                                type="text" 
                                className="form-control" 
                                autoComplete="off"
                                onInput={this.onChangeFilter}
                            />
                            </div>
                                {this.renderResults()}

                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Ver_list_stock
