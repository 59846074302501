import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'

export class VerSellingDatatable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData
        }
    }

    componentDidUpdate(prevProps, prevState){
        //console.log('prevProps',prevProps)
        //console.log('prevState',prevState)
        if(prevProps.myData != prevState.myData){
            this.setState({
                myData: prevProps.myData
            })
        }
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const updatedData = this.state.myData.filter(function (el) {
            return selectedValues.indexOf(el.id) < 0;
        });
        this.setState({
            myData: updatedData
        })
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    

    editProduct(row){
        console.log(row);
        //window.location.reload();
    }

    render() {
        const { pageSize, myClass, multiSelectOption, pagination } = this.props;
        const { myData } = this.state

        const columns = [];
        var deleteSelling  =   this.props.deleteSelling;
        for (var key in myData[0]) {

            let editable = this.renderEditable
            if (key === "Imagen") {
                editable = null;
            }
            if (key === "Estado") {
                editable = null;
            }
            if (key === "avtar") {
                editable = null;
            }
            if (key === "vendor") {
                editable = null;
            }
            if(key === "order_status"){
                editable = null;
            }
            if(key === "Nombre"){
                editable = null;
            }
            if(key === "Email"){
                editable = null;
            }
            if(key === "Celular"){
                editable = null;
            }
            if(key === "Embajador"){
                editable = null;
            }

            if(key === "Rol"){
                editable = null;
            }

            if(key != "id_category")
            {
                columns.push(
                    {
                        Header: <b>{this.Capitalize(key.toString())}</b>,
                        accessor: key,
                        Cell: editable,
                        style: {
                            textAlign: 'center'
                        }
                    });
            }
        }

        if (multiSelectOption == true) {
            columns.push(
                {
                    Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                        onClick={
                            (e) => {
                                if (window.confirm('Are you sure you wish to delete this item?'))
                                    this.handleRemoveRow()
                            }}>Delete</button>,
                    id: 'delete',
                    accessor: str => "delete",
                    sortable: false,
                    style: {
                        textAlign: 'center'
                    },
                    Cell: (row) => (
                        <div>
                            <span >
                                <input type="checkbox" name={row.original.id} defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                    onChange={e => this.selectRow(e, row.original.id)} />
                            </span>
                        </div>
                    ),
                    accessor: key,
                    style: {
                        textAlign: 'center'
                    }
                }
            )
        } else {
            columns.push(
                {
                    Header: <b>Acción</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div style={{ display: 'block' }}>
                            <Link to={`#`} title = "Eliminar"
                            onClick={() => deleteSelling(row)}
                            >
                                <i className="fa fa-trash" style={{ width: 30, fontSize: 20, padding: 11, color: '#e4566e' }}
                                ></i>
                            </Link>
                            <Link title = "Editar"
                                to={`${process.env.PUBLIC_URL}/edit-selling/${row.original.id}`}
                            >
                            <i className="fa fa-pencil" style={{ width: 30, fontSize: 20, padding: 11,color:'rgb(40, 167, 69)' }}></i></Link>
                            
                        
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false
            }
        )
        }

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        )
    }
}

export default VerSellingDatatable
