import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ApiService from '../services/api.service';

const SignupSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').required('nombre requerido'),
  district: Yup.string().nullable().required('distrito requerido'),
  number: Yup.string().required('número requerido'),
  inside: Yup.string().required('interior requerido'),
  link: Yup.string().url().required('link google maps requerido'),
});

const VerAddressForm = ({ tokenJwt, onHandlerSubmit, address }) => {
  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState(null);
  const [typeAddress, setTypeAddress] = useState(null);
  const [typeLeaveYourOrder, setTypeLeaveYourOrder] = useState(null);

  //const [address, setAddress] = useState(_address);
  useEffect(() => {
    if (address) {
      setDistrict({ value: address.ubigeo, label: address.district });
      setTypeAddress({
        value: address.type_address,
        label: address.type_address,
      });
      setTypeLeaveYourOrder({
        value: address.leave_your_order,
        label: address.leave_your_order,
      });
    }
  }, [address]);

  useEffect(() => {
    (async () => {
      if (tokenJwt) {
        const results = await ApiService.getDisttricts(tokenJwt);

        const options = results.map((result) => ({
          value: result.id,
          label: result.name,
        }));

        setDistricts(options);
      }
    })();
  }, [tokenJwt]);

  return (
    <Formik
      initialValues={{
        type_address: address ? address.type_address : '',
        leave_your_order: address ? address.leave_your_order : '',
        name: address ? address.address : '',
        district: address ? address.ubigeo : '',
        number: address ? address.number : '',
        inside: address ? address.interior : '',
        link: address ? address.url_google : '',
      }}
      enableReinitialize
      validationSchema={SignupSchema}
      onSubmit={(values, formikBags) => {
        onHandlerSubmit(
          { 
            ...values,
            ubigeo: district.value, 
            district: district.label 
          },
          formikBags
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form autoComplete="off">
          <div>
            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> AV / CALLE / JIRÓN
              </label>
              <div style={{ width: '50%' }}>
                <Select
                  name="type_address"
                  options={[
                    { value: 'AV', label: 'AVENIDA' },
                    { value: 'CALLE', label: 'CALLE' },
                    { value: 'JIRON', label: 'JIRON' },
                  ]}
                  value={typeAddress}
                  onChange={(option) => {
                    setTypeAddress(option);
                    setFieldValue('type_address', option.value);
                  }}
                />
                {errors.district && touched.district ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.district}
                  </div>
                ) : null}
              </div>
            </div>

            

            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Nombre
              </label>
              <div style={{ width: '50%' }}>
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && touched.name ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Número
              </label>
              <div style={{ width: '50%' }}>
                <input
                  name="number"
                  type="text"
                  className="form-control"
                  value={values.number}
                  onChange={handleChange}
                />
                {errors.number && touched.number ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.number}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Interior
              </label>
              <div style={{ width: '50%' }}>
                <input
                  name="inside"
                  type="text"
                  className="form-control"
                  value={values.inside}
                  onChange={handleChange}
                />
                {errors.inside && touched.inside ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.inside}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Distrito
              </label>
              <div style={{ width: '50%' }}>
                <Select
                  name="district"
                  options={districts}
                  value={district}
                  onChange={(option) => {
                    setDistrict(option);
                    setFieldValue('district', option.value);
                  }}
                />
                {errors.district && touched.district ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.district}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> Link en google maps
              </label>
              <div style={{ width: '50%' }}>
                <input
                  name="link"
                  type="text"
                  className="form-control"
                  value={values.link}
                  onChange={handleChange}
                />
                <a
                  href="https://www.google.com/maps/@-12.0665327,-77.031246,15.74z"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <small id="linkHelp" className="form-text">
                    Ir a google maps
                  </small>
                </a>
                {errors.link && touched.link ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.link}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-xl-3 col-md-4">
                <span>*</span> ¿Con quién dejamos tu pedido?
              </label>
              <div style={{ width: '50%' }}>
                <Select
                  name="leave_your_order"
                  options={[
                    { value: 'Conserje', label: 'Conserje' },
                    { value: 'Cliente recoge', label: 'Cliente recoge' },
                  ]}
                  value={typeLeaveYourOrder}
                  onChange={(option) => {
                    setTypeLeaveYourOrder(option);
                    setFieldValue('leave_your_order', option.value);
                  }}
                />
                {errors.district && touched.district ? (
                  <div className="alert alert-danger" role="alert">
                    {errors.district}
                  </div>
                ) : null}
              </div>
            </div>
            
          </div>
          <button
            className="btn btn-primary"
            disabled={isSubmitting}
            type="submit"
          >
            <span>Guardar</span>
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default VerAddressForm;
