import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/sales-transactions';
import Datatable from '../common/datatable';
import VerChangeAddressDatatable from '../common/ver-change-address-datatable';
import VerDetailsOrderDatatable from '../common/ver-details-order-datatable';
import AuthService from "../services/auth.service";
import ApiService from "../services/api.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Modal from 'react-responsive-modal';
import Moment from 'moment';

export class VerListAddressChange extends Component {
    constructor(props) {
        super(props);
        this.handlerAcept = this.handlerAcept.bind(this);
        this.handlerCancel = this.handlerCancel.bind(this);
        this.handlerDetails = this.handlerDetails.bind(this);
        this.state = {
            open: false,
            dataCateg: [],
            results: [],
            detailResults: [],
            id_user: '',
            id_ambassador: '',
            id_address: '',
            type_address: '',
            name: '',
            number: '',
            interior: '',
            district: '',
            url_google: '',
            ubigeo: '',
            new_type_address: '',
            new_leave_your_order: '',
            new_name: '',
            new_number: '',
            new_interior: '',
            new_district: '',
            new_url_google: '',
            new_ubigeo: '',
            action: '',
        };
    }

    componentWillMount() {
        this.getDataChangeRequest();
    }

    getDataChangeRequest(){
        var user = AuthService.getCurrentUser();
        ApiService.getAddressChangeRequest(user.token).then(
            (res) => {
                if(res && res.status == 'success'){
                    //console.log('address',res.data)
                    const items = res.data.map(function(row) {
                        return { 
                            Nro: row.id, 
                            id_user: row.id_user,
                            Usuario : row.User?row.User.name+' '+ row.User.last_name:'', 
                            status : row.status,
                            Fecha: Moment(row.created_at).format('DD-MM-YYYY'),
                            Tipo: row.flag,
                            data: row,
                        }
                     })
                this.setState({ results:items });
                this.renderResults();
                }
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
    }

    renderResults(){
		return this.state.results.length === 0 
			? ''
			: <VerChangeAddressDatatable
            multiSelectOption={false}
            myData={this.state.results}
            pageSize={this.state.results.length <= 50? this.state.results.length:50}
            pagination={true}
            class="-striped -highlight"
            handlerAcept = {this.handlerAcept}
            handlerCancel = {this.handlerCancel}
            handlerDetails = {this.handlerDetails}
        />
    }   

    handlerDetails(data_row) {
        console.log('data_row',data_row);
        this.setState({ action: data_row.original.data.flag });
        if(data_row.original.data.flag == 'REGISTRAR'){
            this.setState({ new_type_address: data_row.original.data.type_address });
            this.setState({ new_leave_your_order: data_row.original.data.leave_your_order });
            this.setState({ new_name: data_row.original.data.address });
            this.setState({ new_number: data_row.original.data.number });
            this.setState({ new_interior: data_row.original.data.interior });
            this.setState({ new_district: data_row.original.data.district });
            this.setState({ new_url_google: data_row.original.data.url_google });
            this.setState({ new_ubigeo: data_row.original.data.ubigeo });
        }else{
            this.setState({ type_address: data_row.original.data.Addresses.type_address });
            this.setState({ leave_your_order: data_row.original.data.Addresses.leave_your_order });
            this.setState({ name: data_row.original.data.Addresses.address });
            this.setState({ number: data_row.original.data.Addresses.number });
            this.setState({ interior: data_row.original.data.Addresses.interior });
            this.setState({ district: data_row.original.data.Addresses.district });
            this.setState({ url_google: data_row.original.data.Addresses.url_google });
            this.setState({ ubigeo: data_row.original.data.Addresses.ubigeo });
            this.setState({ new_type_address: data_row.original.data.type_address });
            this.setState({ new_leave_your_order: data_row.original.data.leave_your_order });
            this.setState({ new_name: data_row.original.data.address });
            this.setState({ new_number: data_row.original.data.number });
            this.setState({ new_interior: data_row.original.data.interior });
            this.setState({ new_district: data_row.original.data.district });
            this.setState({ new_url_google: data_row.original.data.url_google });
            this.setState({ new_ubigeo: data_row.original.data.ubigeo });
        }

        this.onOpenModal();
    }

    handlerAcept(data_row) {

        var action = data_row.original.data.flag;
        /*var msg = '¿Desea aceptar los cambios?';
        if(action == 'REGISTRAR'){
            var msg = '¿Desea aceptar la nueva dirección?';
        }*/
        var msg = 'Ingrese costo de envío';

        Swal.fire({
            title: 'Confirmar',
            text: msg,
            icon: 'warning',
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){

                if(isNaN(result.value))
                {
                    Swal.fire("Aviso!", "Ingrese un valor válido", "error");
                }else{
        
                var user = AuthService.getCurrentUser();
                var id_address = null;

                if(action == 'ACTUALIZAR'){
                    id_address = data_row.original.data.id_address;
                }

                let data = {
                    'id': data_row.original.data.id,
                    'id_address': id_address,
                    'id_user': data_row.original.data.id_user,
                    'status': 'APROBADO',
                    'delivery': result.value,
                }
                ApiService.putChangeAddressRequest(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            if(action == 'REGISTRAR'){
                                Swal.fire("Hecho!", "Dirección registrada!", "success");
                            }else{
                                Swal.fire("Hecho!", "Cambios realizados!", "success");
                            }
                            //toast.success("Orden Aceptada!");
                            this.setState({results: []});
                            this.getDataChangeRequest();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );
                }
           }else{
                if(result.value == '')
                {
                    Swal.fire("Aviso!", "Debe ingresar un valor", "error");
                }
           }
         })
        //this.props.history.push("/edit-user/"+data_row.original.id);
        //window.location.reload();
    }

    handlerCancel(data_row) {

        var action = data_row.original.data.flag;
        var msg = '¿Desea descartar los cambios?';
        if(action == 'REGISTRAR'){
            var msg = '¿Desea rechazar la nueva dirección?';
        }

        Swal.fire({
            title: 'Confirmar',
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', 
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if(result.value){
                
                var user = AuthService.getCurrentUser();
                var id_address = null;
                
                if(action == 'ACTUALIZAR'){
                    id_address = data_row.original.data.id_address;
                }

                let data = {
                    'id': data_row.original.data.id,
                    'id_address': id_address,
                    'id_user': data_row.original.data.id_user,
                    'status': 'RECHAZADO',
                }
                
                ApiService.putChangeAddressRequest(user.token,data).then(
                    (res) => {
                        if(res && res.status == 'success'){
                            if(action == 'REGISTRAR'){
                                Swal.fire("Hecho!", "Dirección rechazada!", "success");
                            }else{
                                Swal.fire("Hecho!", "Cambios descartados!", "success");
                            }
                            this.setState({results: []});
                            this.getDataChangeRequest();
                        }
                    },
                    error => {
                      const resMessage =
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                  );




           }
         })
        //this.props.history.push("/edit-user/"+data_row.original.id);
        //window.location.reload();
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentWillReceiveProps(){
        this.getDataChangeRequest()
    }

    render() {
        const { open } = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Solicitud de cambios" parent="Cambios" />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Lista de Direcciones</h5>
                                </div>
                                <div className="card-body">
                                    <div id="batchDelete" className="transactions">
                                        {this.renderResults()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={open} onClose={this.onCloseModal} >

                <div className="modal-header">
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">Usuario: {this.state.id_ambassador}</h5>
                </div>
                <div className="modal-body">
                    {
                        this.state.action==='REGISTRAR'?
                        <div className="row">
                            <div className="col-md-12" style={{width: '30rem'}}>
                            <label style={{width: '100%',textAlign: 'center',fontWeight: 'bold'}}>Datos:</label>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Av/ Calle/ Jirón:</label>
                                {this.state.new_type_address}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>¿Con quién dejamos tu pedido?</label>
                                {this.state.new_leave_your_order}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Calle:</label>
                                {this.state.new_name}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Número:</label>
                                {this.state.new_number}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Interior:</label>
                                {this.state.new_interior}
                            </div>
                            <div style={{display: 'flex'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Distrito:</label>
                                {this.state.new_district}
                            </div>
                            <div style={{display: 'grid'}}>
                                <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Url Google:</label>
                                {this.state.new_url_google}
                            </div>                            
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-6">
                                <label style={{width: '100%',textAlign: 'center',fontWeight: 'bold'}}>Datos Actuales:</label>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Av/ Calle/ Jirón:</label>
                                    {this.state.type_address}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>¿Con quién dejamos tu pedido?</label>
                                    {this.state.leave_your_order}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Calle:</label>
                                    {this.state.name}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Número:</label>
                                    {this.state.number}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Interior:</label>
                                    {this.state.interior}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Distrito:</label>
                                    {this.state.district}
                                </div>
                                <div style={{display: 'grid'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Url Google:</label>
                                    {this.state.url_google}
                                </div>
                            </div>                        
                            <div className="col-md-6">
                                <label style={{width: '100%',textAlign: 'center',fontWeight: 'bold'}}>Datos por Actualizar:</label>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Av/ Calle/ Jirón:</label>
                                    {this.state.new_type_address}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>¿Con quién dejamos tu pedido?</label>
                                    {this.state.new_leave_your_order}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Calle:</label>
                                    {this.state.new_name}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Número:</label>
                                    {this.state.new_number}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Interior:</label>
                                    {this.state.new_interior}
                                </div>
                                <div style={{display: 'flex'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Distrito:</label>
                                    {this.state.new_district}
                                </div>
                                <div style={{display: 'grid'}}>
                                    <label style={{marginRight: '0.5rem',fontWeight: 'bold'}}>Url Google:</label>
                                    {this.state.new_url_google}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="modal-footer">
                </div>
                </Modal>
            </Fragment>
        )
    }
}

export default VerListAddressChange
